
import store from '@/store'
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner/index.vue'
import { ActionTypes } from '@/store/auth/action-types'
import { scopedTranslation } from '@/i18n'
import { NOTIFICATION_TYPES } from '@/components/constants'

export default defineComponent({
  setup() {
    const { sT } = scopedTranslation('login')

    return { sT }
  },
  data: (): {
    email: string
    loading: boolean
    password: string
  } => ({
    email: '',
    loading: false,
    password: '',
  }),
  components: {
    LoadingSpinner,
  },
  computed: {
    adEnabled(): boolean {
      return !!process.env.VUE_APP_AAD_CLIENT_ID
    },
  },
  methods: {
    async afterLogin() {
      if (this.$store.getters.authStatus === 'success') {
        this.$router.push('/')
      } else {
        this.$notify({
          type: NOTIFICATION_TYPES.ERROR,
          text: this.$store.getters.authErrorMessage ?? this.sT('loginFailed'),
        })
      }
    },
    async adLogin() {
      this.loading = true
      await this.$store.dispatch(ActionTypes.AD_LOGIN)
      this.loading = false
      this.afterLogin()
    },
    async sessionLogin() {
      const email = this.email
      const password = this.password
      this.loading = true
      await this.$store.dispatch(ActionTypes.SESSION_LOGIN, {
        email,
        password,
      })
      this.loading = false
      this.afterLogin()
    },
  },
  beforeCreate() {
    if (store.getters.currentUser) {
      this.$router.push('/')
    }
  },
})
