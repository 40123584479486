
import { onMounted, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import '@fontsource/source-sans-pro'

import DefaultLayout from '@/views/layouts/DefaultLayout/index.vue'
import { useStore } from './utils/useStore'
import { ActionTypes as AuthActionTypes } from './store/auth/action-types'
import LoadingSpinner from '@/components/LoadingSpinner/index.vue'

export default defineComponent({
  name: 'App',
  components: { DefaultLayout, LoadingSpinner },
  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref<boolean>(true)

    const logout = async () => {
      await store.dispatch(AuthActionTypes.LOGOUT)
      router.push('/login')
    }

    const fetchUserData = async () => {
      try {
        await store.dispatch(AuthActionTypes.FETCH_USER)
        loading.value = false
      } catch (_error) {
        if (!router.currentRoute.value.meta.requiresAuth) await logout()
      }
    }

    onMounted(fetchUserData)

    return { loading, router, store }
  },
})
