import { createI18n } from 'vue-i18n'
import deTranslations from '@/locales/de.json'
import enTranslations from '@/locales/en.json'
import plTranslations from '@/locales/pl.json'
import uaTranslations from '@/locales/ua.json'
import { LOCALES, STORAGE_KEYS } from './config/config'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem(STORAGE_KEYS.SELECTED_LOCALE) || LOCALES.EN,
  fallbackLocale: LOCALES.EN,
  globalInjection: true,
  messages: {
    de: deTranslations,
    en: enTranslations,
    pl: plTranslations,
    ua: uaTranslations,
  },
})

export const t = i18n.global.t

export const availableLocales: Array<string> = i18n.global.availableLocales

// Allow to define outer scope when setting up deTranslations
export const scopedTranslation = (
  firstLevelKey: keyof typeof enTranslations,
): Record<string, (secondLevelKey: string) => string> => {
  return {
    sT: (secondLevelKey: string) => {
      return i18n.global.t(`${firstLevelKey}.${secondLevelKey}`)
    },
  }
}

export default i18n
