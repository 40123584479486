import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLayoutLinks = _resolveComponent("AppLayoutLinks")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", null, [
      _createVNode(_component_AppLayoutLinks)
    ]),
    _createElementVNode("main", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}