import { MutationTree } from 'vuex'
import { IUser } from '@/api/AuthApi/types'
import { STORAGE_KEYS } from '@/config/config'
import i18n, { t } from '@/i18n'
import { AuthStateTypes } from '../interfaces'
import { MutationTypes } from './mutation-types'

export const mutations: MutationTree<AuthStateTypes> & AuthStateTypes = {
  [MutationTypes.LOGIN_START](state: AuthStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.LOGIN_SUCCESS](
    state: AuthStateTypes,
    payload: { user: IUser },
  ) {
    state.status = 'success'
    state.errorMessage = ''
    state.selectedLocale = payload.user.language
    state.user = payload.user
  },
  [MutationTypes.LOGIN_ERROR](state: AuthStateTypes, payload?: string) {
    state.status = 'error'
    state.errorMessage = payload ? payload : t('meta.unknownError')
    state.user = null
  },
  [MutationTypes.LOGOUT](state: AuthStateTypes) {
    state.status = ''
    state.errorMessage = ''
    state.user = null
  },
  [MutationTypes.FETCH_USER_START](state: AuthStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.FETCH_USER_SUCCESS](state: AuthStateTypes, payload: IUser) {
    state.status = 'success'
    state.errorMessage = ''
    state.user = payload
    state.selectedLocale = payload.language
  },
  [MutationTypes.FETCH_USER_ERROR](state: AuthStateTypes, payload?: string) {
    state.status = 'error'
    state.errorMessage = payload ? payload : t('meta.unknownError')
    state.user = null
  },
  setLanguage(state: AuthStateTypes, payload: string) {
    document.documentElement.lang = payload
    i18n.global.locale.value = payload
    state.selectedLocale = payload
    localStorage.setItem(STORAGE_KEYS.SELECTED_LOCALE, payload)
  },
}
