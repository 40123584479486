import * as Sentry from '@sentry/vue'

import { get, patch } from '../base'
import { ROUTES } from '@/config/config'
import { IUser } from '../AuthApi/types'
import { GetResponseData } from './types'
import { isSuccessful } from '../utils'
import store from '@/store'
import { MutationTypes } from '@/store/auth/mutation-types'

export default {
  getUser: async (): Promise<IUser | null> => {
    const response = await get<GetResponseData>({ path: ROUTES.USER.getUser })
    if (isSuccessful(response)) {
      return response.data?.user
    } else {
      Sentry.captureException(response.errors[0])
      store.commit(MutationTypes.FETCH_USER_ERROR, response.errors[0])
      return null
    }
  },
  updateUserLanguage: async (language: string): Promise<IUser | null> => {
    const response = await patch<GetResponseData>({
      path: ROUTES.USER.getUser,
      queryParams: { language: language },
    })
    if (isSuccessful(response)) {
      return response.data.user
    } else {
      return null
    }
  },
}
