<template>
  <nav v-if="isLoggedIn">
    <router-link class="logo__link-container" to="/">
      <img class="logo__image" alt="logo" src="@/assets/logo.svg" />
    </router-link>
    <div class="icons__link-container">
      <router-link to="/user">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="48"
          viewBox="0 96 960 960"
          width="48"
        >
          <path
            d="M222 801q63-44 125-67.5T480 710q71 0 133.5 23.5T739 801q44-54 62.5-109T820 576q0-145-97.5-242.5T480 236q-145 0-242.5 97.5T140 576q0 61 19 116t63 109Zm257.814-195Q422 606 382.5 566.314q-39.5-39.686-39.5-97.5t39.686-97.314q39.686-39.5 97.5-39.5t97.314 39.686q39.5 39.686 39.5 97.5T577.314 566.5q-39.686 39.5-97.5 39.5Zm.654 370Q398 976 325 944.5q-73-31.5-127.5-86t-86-127.266Q80 658.468 80 575.734T111.5 420.5q31.5-72.5 86-127t127.266-86q72.766-31.5 155.5-31.5T635.5 207.5q72.5 31.5 127 86t86 127.032q31.5 72.532 31.5 155T848.5 731q-31.5 73-86 127.5t-127.032 86q-72.532 31.5-155 31.5ZM480 916q55 0 107.5-16T691 844q-51-36-104-55t-107-19q-54 0-107 19t-104 55q51 40 103.5 56T480 916Zm0-370q34 0 55.5-21.5T557 469q0-34-21.5-55.5T480 392q-34 0-55.5 21.5T403 469q0 34 21.5 55.5T480 546Zm0-77Zm0 374Z"
            fill="currentColor"
          />
        </svg>
      </router-link>
      <a @click="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="48"
          viewBox="0 96 960 960"
          width="48"
        >
          <path
            d="M180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621 444l43-43 176 176-174 174Z"
            fill="currentColor"
          />
        </svg>
      </a>
    </div>
  </nav>
</template>

<script>
import { scopedTranslation } from '@/i18n'
import { ActionTypes } from '@/store/auth/action-types'
import { defineComponent } from '@vue/runtime-core'
import { NOTIFICATION_TYPES } from '@/components/constants'

export default defineComponent({
  name: 'AppLayoutLinks',
  setup() {
    const { sT } = scopedTranslation('appLayoutLinks')

    return {
      sT,
    }
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.getters.currentUser
    },
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch(ActionTypes.LOGOUT)
      } catch (error) {
        this.$notify({
          type: NOTIFICATION_TYPES.ERROR,
          text: this.sT('logoutFailed'),
        })
      }
      this.$router.push('/login')
    },
  },
})
</script>

<style scoped>
@import './AppLayoutLinks.css';
</style>
