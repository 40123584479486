
import { defineComponent } from '@vue/runtime-core'
import Login from '@/components/Login/index.vue'

export default defineComponent({
  name: 'LoginView',
  components: {
    Login,
  },
})
