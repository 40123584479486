import { createStore, ModuleTree } from 'vuex'
import authStore from './auth'
import { AuthStoreModuleTypes } from './auth/types'
import containerStore from './container'
import { ContainerStoreModuleTypes } from './container/types'
import { IRootState } from './interfaces'

const modules: ModuleTree<IRootState> = {
  authStore,
  containerStore,
}

const store = createStore({
  modules,
})

type StoreModules = {
  authStore: AuthStoreModuleTypes
  containerStore: ContainerStoreModuleTypes
}

export type Store = ContainerStoreModuleTypes<
  Pick<StoreModules, 'containerStore'>
> &
  ContainerStoreModuleTypes<Pick<StoreModules, 'containerStore'>> &
  AuthStoreModuleTypes<Pick<StoreModules, 'authStore'>>

export default store
