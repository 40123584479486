import { ContainerStateTypes } from '../interfaces'

export const state: ContainerStateTypes = {
  status: '',
  containers: [],
  fractions: [],
  fullContainer: null,
  emptyContainer: null,
  originZone: null,
  targetZone: null,
}
