export default {
  "meta": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behälter"])},
    "errorOoops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups!"])},
    "fraction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Fraktion"]), _normalize([_interpolate(_named("n")), " Fraktionen"])])},
    "genericApiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Verarbeiten der Anfrage ist ein Fehler aufgetreten."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke!"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])}
  },
  "appLayoutLinks": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
    "logoutFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout fehlgeschlagen"])}
  },
  "availableOrderTypes": {
    "EmptyingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeren"])},
    "FreeSwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frei Wechseln"])},
    "GatheringOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammeln"])},
    "OverheadLoadingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜKL"])},
    "PickupAndWeighOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholen und Wiegen"])},
    "PickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholen"])},
    "PositioningOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufstellen"])},
    "SwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln"])}
  },
  "comment": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar (optional)"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibe hier deinen Kommentar..."])}
  },
  "company": {
    "availableOrderTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsarten"])},
    "noOrderTypesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider sind für dieses Unternehmen keine Auftragsarten verfügbar."])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst ein Unternehmen aus."])}
  },
  "container": {
    "typeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behältertyp-Name"])}
  },
  "createOrderView": {
    "scanContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne Behälter Qr-Code"])},
    "scanAdditionalContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne weiteren Behälter Qr-Code (optional)"])},
    "scanPlaceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne Standort Qr-Code"])}
  },
  "login": {
    "adLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login mit Azure AD"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine E-Mail-Adresse"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login fehlgeschlagen"])},
    "loginSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login erfolgreich"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte melde dich an."])}
  },
  "newOrder": {
    "selectAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftrag erstellen"])},
    "createNewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Auftrag erstellen"])},
    "containerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "emptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerer Behälter"])},
    "fullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voller Behälter"])},
    "originZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsort"])},
    "scanEmptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne den QR-Code des leeren Behälters"])},
    "scanFullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne den QR-Code des vollen Behälters"])},
    "scanOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne den QR-Code des Auftragsortes"])},
    "scanTargetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne den QR-Code des Zielortes"])},
    "selectSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle das Werksgelände"])},
    "selectOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle den Auftragsort"])},
    "targetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielort"])},
    "zoneDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort gelöscht"])}
  },
  "registerOrder": {
    "containerAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Container wurde bereits gescannt"])},
    "containerUsedInActiveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist bereits in aktivem Auftrag eingeplant."])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgesendet"])},
    "fetchContainerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Abrufen des gescannten Behälters ist ein Fehler aufgetreten"])},
    "fetchPlaceError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Abrufen des gescannten Standorts ist ein Fehler aufgetreten"])},
    "orderAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diesen Container wurde bereits ein Auftrag erstellt"])},
    "orderCreateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Erstellen des Auftrags ist ein Fehler aufgetreten"])},
    "orderCreateIdMissingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftrag-Erstellung fehlgeschlagen - ID fehlt"])},
    "orderSuccessfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftrag erstellt"])},
    "scanAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
    "scanAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut"])},
    "scanContainerToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte zuerst Behälter scannen"])},
    "scanPlaceToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte zuerst Standort scannen"])},
    "selectFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle Fraktion"])}
  },
  "qrScanner": {
    "noCameraAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Kamerazugriff"])},
    "noSuitableCameraDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kamera gefunden"])},
    "noSaveConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine sichere Verbindung (https)"])},
    "cameraBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera beschäftigt"])},
    "browserLackingFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser Kamera Feature fehlt"])}
  },
  "order": {
    "types": {
      "emptying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeren"])},
      "freeSwitching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frei Wechseln"])},
      "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammeln"])},
      "overheadLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜKL"])},
      "pickupAndWeigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholen und Wiegen"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholen"])},
      "positioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufstellen"])},
      "switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln"])}
    }
  },
  "pageNotFound": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Seite nicht gefunden"])}
  },
  "user": {
    "changeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache ändern"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldedaten"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "updateErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung fehlgeschlagen"])},
    "selectedLocale": {
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
  }
}