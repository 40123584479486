import { get, getWithPagination } from '../base'
import { isSuccessful } from '../utils'
import { GetResponseData, IZone } from './types'

export default {
  getByQrCode: async (identifier: string): Promise<IZone | null> => {
    const response = await get<GetResponseData>({
      path: `zones/?identifier=${identifier}`,
    })
    if (isSuccessful(response)) {
      return response.data?.zones[0]
    } else {
      return null
    }
  },
  getAllForCompany: async (companyUuid: string): Promise<IZone[]> => {
    return await getWithPagination<IZone>({
      path: `companies/${companyUuid}/zones`,
      valuePath: 'zones',
    })
  },
}
