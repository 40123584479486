import { GetResponseData, IContainer } from './types'
import { get } from '../base'
import { isSuccessful } from '../utils'

export default {
  getByQrCode: async (identifier: string): Promise<IContainer | null> => {
    const response = await get<GetResponseData>({
      path: `containers/?identifier=${identifier}`,
    })
    return isSuccessful(response) ? response.data.containers[0] : null
  },
}
