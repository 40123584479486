<template>
  <div>{{ sT('infoText') }}</div>
</template>

<script>
import { scopedTranslation } from '@/i18n'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'PageNotFound',
  setup() {
    const { sT } = scopedTranslation('pageNotFound')
    return { sT }
  },
})
</script>
