import { post } from '../base'
import { ROUTES } from '@/config/config'
import { IEmptyingOrder, EmptyingOrderResponseData } from './types'
import { isSuccessful } from '../utils'

export default {
  create: async ({
    containerUuids,
    zoneUuid,
    fractionUuid,
    siteUuid,
  }: {
    containerUuids: Array<string>
    zoneUuid: string
    fractionUuid: string
    siteUuid: string
  }): Promise<IEmptyingOrder[] | null> => {
    const response = await post<EmptyingOrderResponseData>({
      path: ROUTES.EMPTYING_ORDER.create,
      queryParams: {
        container_uuids: containerUuids,
        origin_zone_uuid: zoneUuid,
        fraction_uuid: fractionUuid,
        site_uuid: siteUuid,
      },
    })
    if (isSuccessful(response)) {
      return response.data.emptying_orders
    } else {
      response.errors
      return null
    }
  },
}
