import { Module } from 'vuex'
import { ContainerStateTypes, IRootState } from '../interfaces'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const containerStore: Module<ContainerStateTypes, IRootState> = {
  state,
  getters,
  mutations,
  actions,
}

export default containerStore
