export enum MutationTypes {
  CLEAR_STATE = 'CLEAR_STATE',
  FETCH_START = 'FETCH_START',
  FETCH_CONTAINER_SUCCESS = 'FETCH_CONTAINER_SUCCESS',
  FETCH_FULL_CONTAINER_SUCCESS = 'FETCH_FULL_CONTAINER_SUCCESS',
  FETCH_EMPTY_CONTAINER_SUCCESS = 'FETCH_EMPTY_CONTAINER_SUCCESS',
  FETCH_ERROR = 'FETCH_ERROR',
  REMOVE_CONTAINERS = 'REMOVE_CONTAINERS',
  REMOVE_CONTAINER = 'REMOVE_CONTAINER',
  ZONE_LOCATION_START = 'ZONE_LOCATION_START',
  ZONE_LOCATION_SUCCESS = 'ZONE_LOCATION_SUCCESS',
  ZONE_LOCATION_ERROR = 'ZONE_LOCATION_ERROR',
  REMOVE_ZONE = 'REMOVE_ZONE',
  ORIGIN_ZONE_LOCATION_START = 'ORIGIN_ZONE_LOCATION_START',
  ORIGIN_ZONE_LOCATION_SUCCESS = 'ORIGIN_ZONE_LOCATION_SUCCESS',
  ORIGIN_ZONE_LOCATION_ERROR = 'ORIGIN_ZONE_LOCATION_ERROR',
  REMOVE_ORIGIN_ZONE = 'REMOVE_ORIGIN_ZONE',
  TARGET_ZONE_LOCATION_START = 'TARGET_ZONE_LOCATION_START',
  TARGET_ZONE_LOCATION_SUCCESS = 'TARGET_ZONE_LOCATION_SUCCESS',
  TARGET_ZONE_LOCATION_ERROR = 'TARGET_ZONE_LOCATION_ERROR',
  REMOVE_TARGET_ZONE = 'REMOVE_TARGET_ZONE',
  FETCH_FRACTIONS_START = 'FETCH_FRACTIONS_START',
  FETCH_FRACTIONS_SUCCESS = 'FETCH_FRACTIONS_SUCCESS',
  FETCH_FRACTIONS_ERROR = 'FETCH_FRACTIONS_ERROR',
  REMOVE_FRACTIONS = 'REMOVE_FRACTIONS',
}
