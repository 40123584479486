import { get } from '@/api/base'
import { ROUTES } from '@/config/config'
import { ISite, GetResponseData } from './types'
import { isSuccessful } from '../utils'

export default {
  getAllForCompany: async (companyUuid: string): Promise<ISite[] | null> => {
    const response = await get<GetResponseData>({
      path: ROUTES.SITE.getAllForCompany,
      queryParams: { company_uuid: companyUuid },
    })
    if (isSuccessful(response)) {
      return response.data.sites
    } else {
      return null
    }
  },
}
