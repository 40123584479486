import { get } from '../base'
import {
  GetResponseData,
  GetFractionsResponseData,
  ICompany,
  IFraction,
} from './types'
import { isSuccessful } from '../utils'

export default {
  get: async (companyUuid: string): Promise<ICompany | null> => {
    const response = await get<GetResponseData>({
      path: `companies/${companyUuid}`,
    })
    if (isSuccessful(response)) {
      return response.data?.company
    } else {
      return null
    }
  },
  getFractions: async (companyUuid: string): Promise<IFraction[] | null> => {
    const response = await get<GetFractionsResponseData>({
      path: `companies/${companyUuid}/fractions`,
    })
    if (isSuccessful(response)) {
      return response.data?.fractions
    } else {
      return null
    }
  },
}
