import {
  AccountInfo,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser'
import * as Sentry from '@sentry/vue'

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AAD_CLIENT_ID,
    authority: process.env.VUE_APP_AAD_AUTHORITY,
    knownAuthorities: [process.env.VUE_APP_AAD_KNOWN_AUTHORITY],
    redirectUri: process.env.VUE_APP_AAD_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_AAD_POST_LOGOUT_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            Sentry.captureException(message)
            console.error(message)
            return
          case LogLevel.Info:
            Sentry.captureMessage(message)
            return
          case LogLevel.Warning:
            Sentry.captureMessage(message)
            return
        }
      },
      piiLoggingEnabled: false,
    },
  },
}

const publicClientApplication = new PublicClientApplication(
  <Configuration>msalConfig,
)

const makeAccesstokenRequest = () => {
  const account = publicClientApplication.getAllAccounts()[0]
  return {
    account,
    forceRefresh: false,
    scopes: [process.env.VUE_APP_AAD_SCOPE],
  }
}

export const loginWithAdRedirect =
  async (): Promise<AccountInfo | null | void> => {
    try {
      const response = await publicClientApplication.handleRedirectPromise()
      if (response !== null) {
        console.log({ response: response })
        return response.account
      } else {
        console.log('calling loginRedirect')
        const accessTokenRequest = makeAccesstokenRequest()
        return await publicClientApplication.loginRedirect(
          <RedirectRequest>accessTokenRequest,
        )
      }
    } catch (error) {
      Sentry.captureException(error)
      console.debug(error)
      return null
    }
  }

// This uses the silent refresh mechanism to get a token to be used by the api.
// The silent refresh is caching and validating the tokens lifetime for us.
// If silent refresh fails, we fall back to the redirect interaction for acquiring the token
//
// Based on this example [as of 2023-06-10]:
// https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2#acquire-a-token-with-a-redirect
export const getAdTokenRedirect = async (): Promise<string | null | void> => {
  try {
    const response = await publicClientApplication.handleRedirectPromise()
    if (response !== null) {
      // Acquiring token silently successful, return access token
      return response.accessToken
    } else {
      const accessTokenRequest = makeAccesstokenRequest()
      // It only works if there is a user account already present
      if (accessTokenRequest.account == null) return null

      try {
        // try silent refresh
        return (
          await publicClientApplication.acquireTokenSilent(
            <SilentRequest>accessTokenRequest,
          )
        ).accessToken
      } catch (error) {
        console.warn(
          'Silent token acquisition failed. Using redirect interaction to acquire token.',
        )
        if (error instanceof InteractionRequiredAuthError) {
          return await publicClientApplication.acquireTokenRedirect(
            <RedirectRequest>accessTokenRequest,
          )
        } else {
          Sentry.captureException(error)
          console.debug(error)
          return null
        }
      }
    }
  } catch (error) {
    Sentry.captureException(error)
    console.debug(error)
    return null
  }
}

export const logoutWithAdRedirect = async (): Promise<void> => {
  const accessTokenRequest = makeAccesstokenRequest()
  // can't if there is no account
  if (accessTokenRequest.account == null) return

  return await publicClientApplication.logoutRedirect(
    <EndSessionRequest>msalConfig,
  )
}
