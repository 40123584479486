export default {
  "meta": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
    "errorOoops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ooops!"])},
    "fraction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Fraction"]), _normalize([_interpolate(_named("n")), " Fractions"])])},
    "genericApiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error while processing the current request."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])}
  },
  "appLayoutLinks": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
    "logoutFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout failed"])}
  },
  "availableOrderTypes": {
    "EmptyingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying"])},
    "FreeSwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free switching"])},
    "GatheringOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering"])},
    "OverheadLoadingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overhead loader"])},
    "PickupAndWeighOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup and Weigh"])},
    "PickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
    "PositioningOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning"])},
    "SwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])}
  },
  "comment": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment (optional)"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your comment here..."])}
  },
  "company": {
    "availableOrderTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order types"])},
    "noOrderTypesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there are no order types available for this company."])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a company first."])}
  },
  "container": {
    "typeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container type name"])}
  },
  "createOrderView": {
    "scanContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan container qr code"])},
    "scanAdditionalContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan additional container qr code (optional)"])},
    "scanPlaceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan place qr code"])}
  },
  "login": {
    "adLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Azure AD"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
    "loginSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login successful"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in."])}
  },
  "newOrder": {
    "selectAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order"])},
    "createNewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new order"])},
    "containerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "emptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty container"])},
    "fullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full container"])},
    "originZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin zone"])},
    "scanEmptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan empty container QR code"])},
    "scanFullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan full container QR code"])},
    "scanOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan origin zone QR code"])},
    "scanTargetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan target zone QR code"])},
    "selectSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select site"])},
    "selectOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select origin zone"])},
    "targetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target zone"])},
    "zoneDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone deleted"])}
  },
  "registerOrder": {
    "containerAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The container was already scanned"])},
    "containerUsedInActiveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is included in an active order already."])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
    "fetchContainerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while fetching the scanned container"])},
    "fetchPlaceError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while fetching the scanned place"])},
    "orderAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order for this container already in progress"])},
    "orderCreateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while creating order"])},
    "orderCreateIdMissingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not create order as id is missing"])},
    "orderSuccessfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successfully created"])},
    "scanAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan again"])},
    "scanAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan another"])},
    "scanContainerToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan container first"])},
    "scanPlaceToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan place first"])},
    "selectFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select fraction"])}
  },
  "qrScanner": {
    "noCameraAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No camera access"])},
    "noSuitableCameraDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No suitable camera device"])},
    "noSaveConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No save connection (no https)"])},
    "cameraBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera busy"])},
    "browserLackingFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser lacking feature"])}
  },
  "order": {
    "types": {
      "emptying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying"])},
      "freeSwitching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free switching"])},
      "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering"])},
      "overheadLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overhead loader"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
      "pickupAndWeigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup and Weigh"])},
      "positioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning"])},
      "switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])}
    }
  },
  "pageNotFound": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Page not found"])}
  },
  "user": {
    "changeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login details"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "updateErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung fehlgeschlagen"])},
    "selectedLocale": {
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  }
}