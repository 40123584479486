
import { defineComponent, reactive, toRefs } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner/index.vue'
import { EVENTS } from '@/config/events'
import { scopedTranslation } from '@/i18n'
import { NOTIFICATION_TYPES } from '@/components/constants'

export default defineComponent({
  name: 'QrScanner',
  components: {
    LoadingSpinner,
  },
  props: {
    title: { type: String, required: false },
  },
  setup(_props, { emit }) {
    const state = reactive({
      data: '',
      loading: false,
      error: '',
    })

    const onDecode = (data: string) => {
      // The scanner returns some codes as URLs
      const sanitizedCode = data.replace(/^https?:\/\//, '')
      state.data = sanitizedCode
      emit(EVENTS.ID_FOUND, sanitizedCode)
    }

    const { sT } = scopedTranslation('qrScanner')

    return {
      ...toRefs(state),
      onDecode,
      sT,
    }
  },
  emits: [EVENTS.ID_FOUND],
  methods: {
    async onInit(promise: Promise<unknown>) {
      this.loading = true
      try {
        await promise
      } catch (error: unknown) {
        if (error instanceof Error) {
          if (error.name === 'NotAllowedError') {
            const errorMessage = this.sT('noCameraAccess')
            this.error = errorMessage
            this.$notify({ type: NOTIFICATION_TYPES.ERROR, text: errorMessage })
          } else if (error.name === 'NotFoundError') {
            const errorMessage = this.sT('noSuitableCameraDevice')
            this.error = errorMessage
            this.$notify({
              type: NOTIFICATION_TYPES.ERROR,
              text: errorMessage,
            })
          } else if (error.name === 'NotSupportedError') {
            const errorMessage = this.sT('noSaveConnection')
            this.error = errorMessage
            this.$notify({
              type: NOTIFICATION_TYPES.ERROR,
              text: errorMessage,
            })
          } else if (error.name === 'NotReadableError') {
            const errorMessage = this.sT('cameraBusy')
            this.error = errorMessage
            this.$notify({
              type: NOTIFICATION_TYPES.ERROR,
              text: errorMessage,
            })
          } else if (error.name === 'OverconstrainedError') {
            const errorMessage = this.sT('noSuitableCameraDevice')
            this.error = errorMessage
            this.$notify({
              type: NOTIFICATION_TYPES.ERROR,
              text: errorMessage,
            })
          } else if (error.name === 'StreamApiNotSupportedError') {
            const errorMessage = this.sT('browserLackingFeature')
            this.error = errorMessage
            this.$notify({
              type: NOTIFICATION_TYPES.ERROR,
              text: errorMessage,
            })
          }
        }
      } finally {
        this.loading = false
      }
    },
  },
})
