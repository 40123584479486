import { ROUTES } from '@/config/config'
import { post, remove } from '../base'
import {
  IUser,
  ILoginParams,
  ILoginResponseData,
  ILogoutResponseData,
} from './types'
import { isSuccessful } from '../utils'
import store from '@/store'
import { MutationTypes } from '@/store/auth/mutation-types'

export default {
  login: async (loginParams: ILoginParams): Promise<IUser | null> => {
    const response = await post<ILoginResponseData>({
      path: ROUTES.AUTH.login,
      queryParams: {
        email: loginParams.email,
        password: loginParams.password,
      },
    })
    if (isSuccessful(response)) {
      return response.data?.user
    } else {
      store.commit(MutationTypes.LOGIN_ERROR, response.errors[0])
      return null
    }
  },
  logout: async (): Promise<ILogoutResponseData> => {
    const response = await remove<ILogoutResponseData>({
      path: ROUTES.AUTH.logout,
    })
    return response
  },
}
