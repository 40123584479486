import { LOCALES, STORAGE_KEYS } from '@/config/config'
import { AuthStateTypes } from '../interfaces'

export const state: AuthStateTypes = {
  status: '',
  errorMessage: '',
  selectedLocale:
    localStorage.getItem(STORAGE_KEYS.SELECTED_LOCALE) || LOCALES.EN,
  user: null,
}
