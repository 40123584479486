import axios from 'axios'
import { ref } from 'vue'
import { notify } from '@kyvg/vue3-notification'

import { baseAuthHeaders } from '@/api/config'
import {
  ErrorResponse,
  Request,
  RequestWithPagination,
  SuccessfulResponse,
} from '@/api/types'
import { NOTIFICATION_TYPES } from '@/components/constants'
import { t } from '@/i18n'
import { isSuccessful } from './utils'

axios.defaults.withCredentials = true

const baseUrl = process.env.VUE_APP_BASE_API
export const requestPending = ref(false)

export async function get<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    response = await axios.get(`${baseUrl}/${path}`, {
      headers: await baseAuthHeaders(),
      ...(!!queryParams && { params: { ...queryParams } }),
    })
    return {
      success: true,
      data: response.data,
    }
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

export async function getWithPagination<ResultType>({
  path,
  queryParams,
  valuePath,
}: RequestWithPagination): Promise<ResultType[]> {
  const values: ResultType[] = []
  const urlDelimiter = path.includes('?') ? '&' : '?'
  let totalPages: number | null = null

  for (let page = 1; totalPages == null || page <= totalPages; page++) {
    const response = await get({
      path: `${path}${urlDelimiter}page=${page}`,
      ...(!!queryParams && { ...queryParams }),
    })

    if (isSuccessful(response)) {
      totalPages = (
        response.data as unknown as {
          meta: {
            pagination: {
              total_pages: number
            }
          }
        }
      ).meta.pagination.total_pages

      const dataValues = (
        response.data as unknown as {
          [valuePath: string]: ResultType[]
        }
      )[valuePath]

      values.push(...dataValues)
    }
  }
  return values
}

export async function patch<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    let params = {
      ...(!!queryParams && { ...queryParams }),
    }
    if (queryParams instanceof FormData) {
      params = queryParams
    }
    response = await axios.patch(`${baseUrl}/${path}`, params, {
      headers: await baseAuthHeaders(),
    })
    return { success: true, data: response.data }
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

export async function post<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    let params = {
      ...(!!queryParams && { ...queryParams }),
    }
    if (queryParams instanceof FormData) {
      params = queryParams
    }
    response = await axios.post(`${baseUrl}/${path}`, params, {
      headers: await baseAuthHeaders(),
    })
    return {
      success: true,
      data: response.data,
    } as SuccessfulResponse<ResultType>
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

export async function remove<ResultType>({
  path,
  queryParams,
}: Request): Promise<SuccessfulResponse<ResultType> | ErrorResponse> {
  let response
  try {
    requestPending.value = true
    response = await axios.delete(`${baseUrl}/${path}`, {
      headers: await baseAuthHeaders(),
      ...(!!queryParams && { params: { ...queryParams } }),
    })

    return {
      success: true,
      data: response.data,
    }
  } catch (error) {
    return handleError(error)
  } finally {
    requestPending.value = false
  }
}

function handleError(error: unknown): ErrorResponse {
  let errors: string[] = []
  let errorStatus = undefined
  if (axios.isAxiosError(error)) {
    errorStatus = error.response?.status
    errors = error.response?.data.errors
      ? error.response?.data.errors
      : [error.response?.data]
  } else {
    errors = [t('meta.genericApiError')]
  }

  showErrorNotification(errors)

  return {
    success: false,
    errors: errors,
    status: errorStatus,
  }
}

function showErrorNotification(errors: string[]) {
  let errorType = NOTIFICATION_TYPES.ERROR
  let errorTitle = t('meta.errorOoops')
  let errorText = errors.join('<br>')
  for (const e of errors) {
    if (e.includes(t('registerOrder.containerUsedInActiveOrder'))) {
      errorType = NOTIFICATION_TYPES.WARN
      errorTitle = t('meta.thankYou')
      errorText = t('registerOrder.orderAlreadyExists')
    }
  }
  if (errors?.length > 0) {
    const id = Date.now()
    notify({
      id: id,
      type: errorType,
      title: errorTitle,
      text: errorText,
      duration: -1,
      data: {
        click: () => {
          notify.close(id)
        },
      },
    })
  }
}
