import { ROUTES } from '@/config/config'
import { post } from '../base'
import { IOrderCreateParams, OrderResponseData, IOrder } from './types'
import { isSuccessful } from '../utils'

export default {
  create: async (order: IOrderCreateParams): Promise<IOrder | null> => {
    const response = await post<OrderResponseData>({
      path: ROUTES.ORDER.create,
      queryParams: { ...order },
    })
    if (isSuccessful(response)) {
      return response.data.order
    } else {
      response.errors
      return null
    }
  },
}
