import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import QrReader from 'vue3-qr-reader'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'

const app = createApp(App).use(i18n)

app.use(QrReader)
/* By registering the qr code scanner globally, multiple custom components
  are registered. For example qr-stream for the usage of our qr code
  scanner. By telling the compiler that we expect a custom element with
  a certain name, we ensure that we are aware of the existence of that
  particular component. */
app.config.compilerOptions.isCustomElement = tag => tag.includes('qr-stream')

const unsupportedSentryEnvironments = ['development', 'test']
if (
  process.env.NODE_ENV &&
  !unsupportedSentryEnvironments.includes(process.env.NODE_ENV)
) {
  Sentry.init({
    app,
    dsn: 'https://2645c859745b4383bddbe0f344af0fa1@o1082260.ingest.sentry.io/4504140209651712',
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['order.staging.binity.io', 'order.binity.io', /^\//],
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

app.use(store).use(router).use(Notifications).mount('#app')
