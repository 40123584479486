import { GetterTree } from 'vuex'
import { AuthStateTypes, IRootState } from '../interfaces'

export const getters: GetterTree<AuthStateTypes, IRootState> & AuthStateTypes =
  {
    currentUser: (state: AuthStateTypes) => {
      return state.user
    },
    getSelectedLocale: (state: AuthStateTypes) => {
      return state.selectedLocale
    },
    authStatus: (state: AuthStateTypes) => {
      return state.status
    },
    authErrorMessage: (state: AuthStateTypes) => {
      return state.errorMessage
    },
  }
