export default {
  "meta": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанія"])},
    "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контейнер"])},
    "errorOoops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс!"])},
    "fraction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Фракція"]), _normalize([_interpolate(_named("n")), " Фракції"])])},
    "genericApiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час обробки запиту сталася помилка."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місцезнаходження"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, оберіть"])},
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо!"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["невідома помилка"])}
  },
  "appLayoutLinks": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти з системи"])},
    "logoutFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося вийти з системи"])}
  },
  "availableOrderTypes": {
    "EmptyingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying"])},
    "FreeSwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free switching"])},
    "GatheringOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering"])},
    "OverheadLoadingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overhead loader"])},
    "PickupAndWeighOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup and Weigh"])},
    "PickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
    "PositioningOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning"])},
    "SwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])}
  },
  "comment": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment (optional)"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your comment here..."])}
  },
  "company": {
    "availableOrderTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order types"])},
    "noOrderTypesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there are no order types available for this company."])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a company first."])}
  },
  "container": {
    "typeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва типу контейнера"])}
  },
  "createOrderView": {
    "scanContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відскануйте Qr-код контейнера"])},
    "scanAdditionalContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсканувати інший Qr-код контейнера (за бажанням)"])},
    "scanPlaceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсканувати місце розташування Qr-коду"])}
  },
  "login": {
    "adLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть за допомогою Azure AD"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть електронну пошту"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вхід в обліковий запис"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося увійти"])},
    "loginSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішний вхід"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть пароль"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, запишіться."])}
  },
  "newOrder": {
    "selectAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order"])},
    "createNewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new order"])},
    "containerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "emptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty container"])},
    "fullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full container"])},
    "originZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin zone"])},
    "scanEmptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan empty container QR code"])},
    "scanFullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan full container QR code"])},
    "scanOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan origin zone QR code"])},
    "scanTargetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan target zone QR code"])},
    "selectSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select site"])},
    "selectOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select origin zone"])},
    "targetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target zone"])},
    "zoneDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone deleted"])}
  },
  "registerOrder": {
    "containerAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контейнер вже відскановано"])},
    "containerUsedInActiveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вже заплановано в активному замовленні."])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправлено"])},
    "fetchContainerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка при скануванні контейнера"])},
    "fetchPlaceError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка при скануванні місця розташування"])},
    "orderAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для цього контейнера вже створено замовлення"])},
    "orderCreateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка при створенні завдання"])},
    "orderCreateIdMissingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося створити завдання - відсутній ідентифікатор"])},
    "orderSuccessfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завдання створено"])},
    "scanAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторити"])},
    "scanAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спробуйте ще раз"])},
    "scanContainerToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спочатку відскануйте контейнер"])},
    "scanPlaceToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спочатку відскануйте місце розташування"])},
    "selectFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть фракцію"])}
  },
  "qrScanner": {
    "noCameraAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає доступу до камери"])},
    "noSuitableCameraDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камер не знайдено"])},
    "noSaveConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає безпечного з'єднання (https)"])},
    "cameraBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камера зайнята"])},
    "browserLackingFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відсутня функція камери браузера"])}
  },
  "order": {
    "types": {
      "emptying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying"])},
      "freeSwitching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free switching"])},
      "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering"])},
      "overheadLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overhead loader"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
      "pickupAndWeigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup and Weigh"])},
      "positioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning"])},
      "switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])}
    }
  },
  "pageNotFound": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Сторінку не знайдено"])}
  },
  "user": {
    "changeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити мову"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані для входу"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
    "updateErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити не вдалося"])},
    "selectedLocale": {
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати зараз"])}
  }
}