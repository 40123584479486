export default {
  "meta": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontener"])},
    "errorOoops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups!"])},
    "fraction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Frakcja"]), _normalize([_interpolate(_named("n")), " Frakcjy"])])},
    "genericApiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas przetwarzania żądania wystąpił błąd."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacja"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę wybrać"])},
    "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękuję!"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany błąd"])}
  },
  "appLayoutLinks": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wylogowanie"])},
    "logoutFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wylogowanie nie powiodło się"])}
  },
  "availableOrderTypes": {
    "EmptyingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying"])},
    "FreeSwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free switching"])},
    "GatheringOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering"])},
    "OverheadLoadingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overhead loader"])},
    "PickupAndWeighOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup and Weigh"])},
    "PickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
    "PositioningOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning"])},
    "SwitchingOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])}
  },
  "comment": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment (optional)"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your comment here..."])}
  },
  "company": {
    "availableOrderTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order types"])},
    "noOrderTypesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there are no order types available for this company."])},
    "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a company first."])}
  },
  "container": {
    "typeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa typu kontenera"])}
  },
  "createOrderView": {
    "scanContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeskanuj kod Qr kontenera"])},
    "scanAdditionalContainerCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeskanuj kod Qr innego kontenera (opcjonalnie)"])},
    "scanPlaceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeskanuj lokalizację Qr-Code"])}
  },
  "login": {
    "adLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się za pomocą Azure AD"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź adres e-mail"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logowanie nie powiodło się"])},
    "loginSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udało się zalogować"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lub"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
    "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź hasło"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę się zalogować."])}
  },
  "newOrder": {
    "selectAlternative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order"])},
    "createNewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new order"])},
    "containerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "emptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty container"])},
    "fullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full container"])},
    "originZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin zone"])},
    "scanEmptyContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan empty container QR code"])},
    "scanFullContainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan full container QR code"])},
    "scanOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan origin zone QR code"])},
    "scanTargetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan target zone QR code"])},
    "selectSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select site"])},
    "selectOriginZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select origin zone"])},
    "targetZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target zone"])},
    "zoneDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone deleted"])}
  },
  "registerOrder": {
    "containerAlreadyScanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojemnik został już zeskanowany"])},
    "containerUsedInActiveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest już zaplanowany w aktywnym zleceniu."])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłano"])},
    "fetchContainerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas pobierania zeskanowanego pojemnika wystąpił błąd"])},
    "fetchPlaceError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił błąd podczas pobierania zeskanowanej lokalizacji"])},
    "orderAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla tego pojemnika zostało już utworzone zlecenie"])},
    "orderCreateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił błąd podczas tworzenia zlecenia"])},
    "orderCreateIdMissingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie zlecenia nie powiodło się - brak identyfikatora"])},
    "orderSuccessfullyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadanie zostało utworzone"])},
    "scanAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "scanAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz"])},
    "scanContainerToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proszę najpierw zeskanować pojemnik"])},
    "scanPlaceToSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najpierw zeskanuj lokalizację"])},
    "selectFraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz frakcję"])}
  },
  "qrScanner": {
    "noCameraAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępu do kamery"])},
    "noSuitableCameraDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono kamery"])},
    "noSaveConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak bezpiecznego połączenia (https)"])},
    "cameraBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera zajęta"])},
    "browserLackingFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak funkcji kamery w przeglądarce"])}
  },
  "order": {
    "types": {
      "emptying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emptying"])},
      "freeSwitching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free switching"])},
      "gathering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering"])},
      "overheadLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overhead loader"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
      "pickupAndWeigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup and Weigh"])},
      "positioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positioning"])},
      "switching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching"])}
    }
  },
  "pageNotFound": {
    "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Nie znaleziono strony"])}
  },
  "user": {
    "changeLocale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień język"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane do logowania"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
    "updateErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana nie powiodła się"])},
    "selectedLocale": {
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "ua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
    },
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij teraz"])}
  }
}