export enum ActionTypes {
  FETCH_CONTAINER = 'FETCH_CONTAINER',
  FETCH_EMPTY_CONTAINER = 'FETCH_EMPTY_CONTAINER',
  FETCH_FULL_CONTAINER = 'FETCH_FULL_CONTAINER',
  FETCH_ZONE = 'FETCH_ZONE',
  FETCH_ORIGIN_ZONE = 'FETCH_ORIGIN_ZONE',
  FETCH_TARGET_ZONE = 'FETCH_TARGET_ZONE',
  REMOVE_CONTAINERS = 'REMOVE_CONTAINERS',
  REMOVE_CONTAINER = 'REMOVE_CONTAINER',
  REMOVE_ZONE = 'REMOVE_ZONE',
  FETCH_FRACTIONS = 'FETCH_FRACTIONS',
  REMOVE_FRACTIONS = 'REMOVE_FRACTIONS',
}
