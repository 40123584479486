import {
  ContainerStateTypes,
  ContainerActionsTypes,
  IRootState,
} from '../interfaces'
import { ActionTypes } from './action-types'
import { MutationTypes } from './mutation-types'
import CompanyApi from '@/api/CompanyApi'
import { IFraction } from '@/api/CompanyApi/types'
import ContainerApi from '@/api/ContainerApi'
import { IContainer } from '@/api/ContainerApi/types'
import ZoneApi from '@/api/ZoneApi'
import { ActionTree, Commit } from 'vuex'

export const actions: ActionTree<ContainerStateTypes, IRootState> &
  ContainerActionsTypes = {
  async [ActionTypes.FETCH_CONTAINER](
    { commit }: { commit: Commit },
    identifier: string,
  ) {
    commit(MutationTypes.FETCH_START)
    const container = await ContainerApi.getByQrCode(identifier)
    if (container) {
      commit(MutationTypes.FETCH_CONTAINER_SUCCESS, container)
    } else {
      commit(MutationTypes.FETCH_ERROR)
    }
  },
  [ActionTypes.REMOVE_CONTAINERS]({ commit }: { commit: Commit }) {
    commit(MutationTypes.REMOVE_CONTAINERS)
  },
  [ActionTypes.REMOVE_CONTAINER](
    { commit }: { commit: Commit },
    payload: IContainer,
  ) {
    commit(MutationTypes.REMOVE_CONTAINER, payload)
  },
  async [ActionTypes.FETCH_EMPTY_CONTAINER](
    { commit }: { commit: Commit },
    identifier: string,
  ) {
    commit(MutationTypes.FETCH_START)
    const container = await ContainerApi.getByQrCode(identifier)
    if (container) {
      commit(MutationTypes.FETCH_EMPTY_CONTAINER_SUCCESS, container)
    } else {
      commit(MutationTypes.FETCH_ERROR)
    }
  },
  async [ActionTypes.FETCH_FULL_CONTAINER](
    { commit }: { commit: Commit },
    identifier: string,
  ) {
    commit(MutationTypes.FETCH_START)
    const container = await ContainerApi.getByQrCode(identifier)
    if (container) {
      commit(MutationTypes.FETCH_FULL_CONTAINER_SUCCESS, container)
    } else {
      commit(MutationTypes.FETCH_ERROR)
    }
  },
  async [ActionTypes.FETCH_ZONE](
    { commit }: { commit: Commit },
    identifier: string,
  ) {
    commit(MutationTypes.ZONE_LOCATION_START)
    const response = await ZoneApi.getByQrCode(identifier)
    if (response) {
      commit(MutationTypes.ZONE_LOCATION_SUCCESS, response)
    } else {
      commit(MutationTypes.ZONE_LOCATION_ERROR)
    }
  },
  async [ActionTypes.FETCH_ORIGIN_ZONE](
    { commit }: { commit: Commit },
    identifier: string,
  ) {
    commit(MutationTypes.ORIGIN_ZONE_LOCATION_START)
    const response = await ZoneApi.getByQrCode(identifier)
    if (response) {
      commit(MutationTypes.ORIGIN_ZONE_LOCATION_SUCCESS, response)
    } else {
      commit(MutationTypes.ORIGIN_ZONE_LOCATION_ERROR)
    }
  },
  async [ActionTypes.FETCH_TARGET_ZONE](
    { commit }: { commit: Commit },
    identifier: string,
  ) {
    commit(MutationTypes.TARGET_ZONE_LOCATION_START)
    const response = await ZoneApi.getByQrCode(identifier)
    if (response) {
      commit(MutationTypes.TARGET_ZONE_LOCATION_SUCCESS, response)
    } else {
      commit(MutationTypes.TARGET_ZONE_LOCATION_ERROR)
    }
  },
  async [ActionTypes.FETCH_FRACTIONS](
    { commit }: { commit: Commit },
    companyUUID: string,
  ) {
    commit(MutationTypes.FETCH_FRACTIONS_START)
    try {
      const response = await CompanyApi.getFractions(companyUUID)
      const fractions = response?.map((fraction: IFraction) => {
        return {
          uuid: fraction.uuid,
          name: fraction.name,
          localized_name: fraction.localized_name,
          stringEN: fraction.stringEN,
          stringDE: fraction.stringDE,
        }
      })
      commit(MutationTypes.FETCH_FRACTIONS_SUCCESS, fractions)
    } catch (error) {
      commit(MutationTypes.FETCH_FRACTIONS_ERROR)
    }
  },
  [ActionTypes.REMOVE_ZONE]({ commit }: { commit: Commit }) {
    commit(MutationTypes.REMOVE_ZONE)
  },
  [ActionTypes.REMOVE_FRACTIONS]({ commit }: { commit: Commit }) {
    commit(MutationTypes.REMOVE_FRACTIONS)
  },
}
