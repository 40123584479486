<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48 24C48 37.255 37.255 48 24 48C10.745 48 0 37.255 0 24C0 10.745 10.745 0 24 0C37.255 0 48 10.745 48 24ZM11.7857 22.6256C11.0006 23.4007 11.0039 24.6568 11.7969 25.4353L21.2339 34.7031C22.7427 36.2093 25.0056 33.9509 23.4967 32.4453L17.1899 26.1573C16.8796 25.8476 16.9884 25.5964 17.4287 25.5964H34.8287C35.7143 25.5964 36.4326 24.8877 36.4326 23.9999C36.4326 23.1176 35.7161 22.4027 34.8287 22.4027H17.4287C16.9862 22.4027 16.8807 22.1511 17.1899 21.8425L23.4967 15.5539C25.0056 14.0495 22.7428 11.7905 21.2339 13.296L11.7857 22.6256Z"
      fill="currentColor"
    />
  </svg>
</template>
