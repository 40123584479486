
import { defineComponent } from '@vue/runtime-core'
import { notify } from '@kyvg/vue3-notification'
import { useRouter } from 'vue-router'

import EmptyingOrderApi from '@/api/EmptyingOrderApi'
import OrderApi from '@/api/OrderApi'
import CommentField from '@/components/CommentField/CommentField.vue'
import LoadingSpinner from '@/components/LoadingSpinner/index.vue'
import { ActionTypes } from '@/store/container/action-types'
import { MutationTypes } from '@/store/container/mutation-types'
import { EVENTS } from '@/config/events'
import i18n, { scopedTranslation } from '@/i18n'
import { NOTIFICATION_TYPES } from '../constants'
import { LOCALES } from '@/config/config'
import { IFraction } from '@/api/CompanyApi/types'
import { IContainer } from '@/api/ContainerApi/types'
import { IOrderCreateParams } from '@/api/OrderApi/types'
import Select from '@/components/Select/Select.vue'
import OrderInfo from './OrderInfo.vue'

interface BaseComponentData {
  commentText: string
  loading: boolean
  orderCreated: boolean
  selectedFractionUuid: string
}

export default defineComponent({
  name: 'RegisterOrder',
  components: { CommentField, LoadingSpinner, Select, OrderInfo },
  setup() {
    const router = useRouter()
    const currentOrderType = router.currentRoute.value.query.type as string
    const currentCompanyUuid = router.currentRoute.value.query
      .companyUuid as string
    const { t } = i18n.global
    const { sT } = scopedTranslation('registerOrder')

    return {
      currentOrderType,
      currentCompanyUuid,
      t,
      sT,
      LOCALES,
    }
  },
  props: {
    originZoneIdentifier: { type: String },
    targetZoneIdentifier: { type: String },
    fullContainerIdentifier: { type: String },
    emptyContainerIdentifier: { type: String },
    siteUuid: { type: String },
  },
  emits: [
    EVENTS.CREATED_ORDER,
    EVENTS.FETCHED_CONTAINER,
    EVENTS.FETCHED_PLACE,
    EVENTS.RESET,
  ],
  data(): BaseComponentData {
    return {
      commentText: '',
      loading: false,
      orderCreated: false,
      selectedFractionUuid: '',
    }
  },
  watch: {
    async fullContainerIdentifier(
      newIdentifier: string,
      oldIdentifier: string,
    ) {
      const identifierChanged = newIdentifier && newIdentifier !== oldIdentifier
      const containerAlreadyScanned =
        !!this.$store.getters.scannedContainers.find(
          (container: IContainer) =>
            container.identifier?.value === newIdentifier,
        )

      if (identifierChanged && !containerAlreadyScanned) {
        await this.fetchContainer(newIdentifier, 'full')
      } else if (containerAlreadyScanned) {
        notify({
          type: NOTIFICATION_TYPES.ERROR,
          text: this.sT('containerAlreadyScanned'),
        })
      }
    },
    async emptyContainerIdentifier(
      newIdentifier: string,
      oldIdentifier: string,
    ) {
      const identifierChanged = newIdentifier && newIdentifier !== oldIdentifier
      if (identifierChanged) {
        await this.fetchContainer(newIdentifier, 'empty')
      }
    },
    async originZoneIdentifier(newIdentifier: string, oldIdentifier: string) {
      if (newIdentifier && newIdentifier !== oldIdentifier) {
        await this.fetchOriginZone(newIdentifier)
        if (
          this.$store.getters.lastAPICallStatus === 'success' &&
          this.$store.getters.availableFractions.length === 0
        ) {
          await this.fetchFractions(
            this.$store.getters.scannedOriginZone?.company_uuid as string,
          )
        }
      }
    },
    async targetZoneIdentifier(newIdentifier: string, oldIdentifier: string) {
      if (newIdentifier && newIdentifier !== oldIdentifier) {
        await this.fetchTargetZone(newIdentifier)
      }
    },
  },
  computed: {
    isEmptyingOrder() {
      return this.currentOrderType === 'EmptyingOrder'
    },
    isGatheringOrder() {
      return this.currentOrderType === 'GatheringOrder'
    },
    containers() {
      return this.$store.getters.scannedContainers
    },
    isContainerPresent() {
      return this.$store.getters.scannedContainers.length > 0
    },
    emptyContainer() {
      return this.$store.getters.scannedEmptyContainer
    },
    fullContainer() {
      return this.$store.getters.scannedFullContainer
    },
    targetZone() {
      return this.$store.getters.scannedTargetZone
    },
    originZone() {
      return this.$store.getters.scannedOriginZone
    },
    isOriginZonePresent() {
      return !!this.$store.getters.scannedOriginZone
    },
    originZoneBodyText() {
      if (!this.isOriginZoneActive) {
        return this.t('newOrder.zoneDeleted')
      } else {
        return this.originZone?.name
      }
    },
    scanButtonText() {
      return this.orderCreated ? this.sT('scanAnother') : this.sT('scanAgain')
    },
    createOrderText() {
      if (this.loading) return ''
      return this.orderCreated
        ? `${this.sT('created')}`
        : this.t('newOrder.createOrder')
    },
    orderFilled() {
      switch (this.currentOrderType) {
        case 'GatheringOrder':
          return this.siteUuid
        case 'EmptyingOrder':
          return (
            this.originZone &&
            this.containers.length > 0 &&
            this.selectedFractionUuid.length > 0 &&
            this.isOriginZoneActive
          )
        case 'FreeSwitchingOrder':
          return this.originZone && this.isOriginZoneActive
        case 'OverheadLoadingOrder':
          return (
            this.originZone &&
            this.fullContainer &&
            this.selectedFractionUuid.length > 0 &&
            this.isOriginZoneActive
          )
        case 'PositioningOrder':
          return this.originZone && this.isOriginZoneActive
        case 'PickupOrder':
        case 'PickupAndWeighOrder':
          return (
            this.originZone &&
            this.fullContainer &&
            this.selectedFractionUuid.length > 0 &&
            this.isOriginZoneActive
          )
        case 'SwitchingOrder':
          return (
            this.originZone &&
            this.fullContainer &&
            this.selectedFractionUuid.length > 0 &&
            this.isOriginZoneActive
          )
        default:
          return true
      }
    },
    isOriginZoneActive() {
      return !this.$store.getters.scannedOrginZone?.deactivated_at
    },
    isTargetZoneActive() {
      return !this.$store.getters.scannedTargetZone?.deactivated_at
    },
    fractionOptions() {
      return this.$store.getters.availableFractions?.map(
        (fraction: IFraction) => ({
          label: fraction.localized_name,
          value: fraction.uuid,
        }),
      )
    },
    showFractionSelect() {
      switch (this.currentOrderType) {
        case 'GatheringOrder':
        case 'FreeSwitchingOrder':
        case 'PositioningOrder':
          return false
        case 'EmptyingOrder':
          return this.originZone && this.containers.length > 0
        case 'OverheadLoadingOrder':
          return this.originZone && this.fullContainer
        case 'PickupOrder':
        case 'PickupAndWeighOrder':
          return this.originZone && this.fullContainer
        case 'SwitchingOrder':
          return this.originZone && this.fullContainer
        default:
          return true
      }
    },
    showCommentfield() {
      const orderTypesWithCommentField = [
        'EmptyingOrder',
        'PickupOrder',
        'PositioningOrder',
        'SwitchingOrder',
      ]
      return orderTypesWithCommentField.includes(this.currentOrderType)
    },
  },
  methods: {
    handleFractionSelect(value: string) {
      this.selectedFractionUuid = value
    },
    handleScanAgain() {
      this.reset()
    },
    async fetchContainer(identifier: string, type = 'full') {
      this.loading = true
      if (type === 'full') {
        if (this.isEmptyingOrder) {
          await this.$store.dispatch(ActionTypes.FETCH_CONTAINER, identifier)
        } else {
          await this.$store.dispatch(
            ActionTypes.FETCH_FULL_CONTAINER,
            identifier,
          )
        }
        if (this.$store.getters.lastAPICallStatus === 'success') {
          this.$emit(EVENTS.FETCHED_CONTAINER, 'full')
        } else {
          notify({
            type: NOTIFICATION_TYPES.ERROR,
            text: this.sT('fetchContainerError'),
          })
        }
      } else {
        await this.$store.dispatch(
          ActionTypes.FETCH_EMPTY_CONTAINER,
          identifier,
        )
        if (this.$store.getters.lastAPICallStatus === 'success') {
          this.$emit(EVENTS.FETCHED_CONTAINER, 'empty')
        } else {
          notify({
            type: NOTIFICATION_TYPES.ERROR,
            text: this.sT('fetchContainerError'),
          })
        }
      }
      this.loading = false
    },
    async fetchFullContainer(identifier: string) {
      this.loading = true
      await this.$store.dispatch(ActionTypes.FETCH_FULL_CONTAINER, identifier)
      if (this.$store.getters.lastAPICallStatus === 'success') {
        this.$emit(EVENTS.FETCHED_CONTAINER, 'full')
      } else {
        notify({
          type: NOTIFICATION_TYPES.ERROR,
          text: this.sT('fetchContainerError'),
        })
      }
      this.loading = false
    },
    async fetchEmptyContainer(identifier: string) {
      this.loading = true
      await this.$store.dispatch(ActionTypes.FETCH_EMPTY_CONTAINER, identifier)
      if (this.$store.getters.lastAPICallStatus === 'success') {
        this.$emit(EVENTS.FETCHED_CONTAINER, 'empty')
      } else {
        notify({
          type: NOTIFICATION_TYPES.ERROR,
          text: this.sT('fetchContainerError'),
        })
      }
      this.loading = false
    },
    async fetchOriginZone(identifier: string) {
      this.loading = true
      await this.$store.dispatch(ActionTypes.FETCH_ORIGIN_ZONE, identifier)
      if (this.$store.getters.lastAPICallStatus === 'success') {
        this.$emit(EVENTS.FETCHED_PLACE, 'origin')
      }
      this.loading = false
    },
    async fetchTargetZone(identifier: string) {
      this.loading = true
      await this.$store.dispatch(ActionTypes.FETCH_TARGET_ZONE, identifier)
      if (this.$store.getters.lastAPICallStatus === 'success') {
        this.$emit(EVENTS.FETCHED_PLACE, 'target')
      }
      this.loading = false
    },
    async fetchFractions(companyUUID: string) {
      this.loading = true
      await this.$store.dispatch(ActionTypes.FETCH_FRACTIONS, companyUUID)
      this.loading = false
    },
    async createOrder() {
      let response = null
      if (this.isEmptyingOrder) {
        const containerUuids = this.$store.getters.scannedContainers.map(
          (container: IContainer) => container.uuid,
        )
        const zoneUuid = this.$store.getters.scannedOriginZone?.uuid
        const siteUuid = this.$store.getters.scannedOriginZone?.site_uuid
        const fractionUuid = this.selectedFractionUuid

        if (containerUuids.length > 0 && !!zoneUuid) {
          response = await EmptyingOrderApi.create({
            containerUuids: containerUuids,
            zoneUuid: zoneUuid,
            siteUuid: siteUuid,
            fractionUuid: fractionUuid,
          })
        } else {
          const id = Date.now()
          notify({
            id,
            type: NOTIFICATION_TYPES.ERROR,
            text: this.sT('orderCreateIdMissingError'),
            duration: -1,
            data: {
              click: () => {
                notify.close(id)
              },
            },
          })
        }
      } else {
        const params: IOrderCreateParams = {
          comment: this.commentText,
          company_uuid: this.currentCompanyUuid,
          fraction_uuid: this.selectedFractionUuid,
          full_container_uuid: this.$store.getters.scannedFullContainer?.uuid,
          empty_container_uuid: this.$store.getters.scannedEmptyContainer?.uuid,
          site_uuid:
            this.siteUuid || this.$store.getters.scannedOriginZone?.site_uuid,
          origin_zone_uuid: this.$store.getters.scannedOriginZone?.uuid,
          target_zone_uuid: this.$store.getters.scannedTargetZone?.uuid,
          type: this.currentOrderType,
        }
        response = await OrderApi.create(params)
      }

      if (response) {
        this.orderCreated = true
        notify({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: this.t('meta.thankYou'),
          text: this.sT('orderSuccessfullyCreated'),
        })
        this.$emit(EVENTS.CREATED_ORDER)
      }
    },
    reset() {
      this.$emit(EVENTS.RESET)
      this.orderCreated = false
      this.selectedFractionUuid = ''
      this.$store.commit(MutationTypes.CLEAR_STATE)
    },
  },
})
