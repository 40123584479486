import { Module } from 'vuex'
import { AuthStateTypes, IRootState } from '../interfaces'
import { state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const authStore: Module<AuthStateTypes, IRootState> = {
  state,
  getters,
  mutations,
  actions,
}

export default authStore
