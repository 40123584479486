import { IFraction } from '@/api/CompanyApi/types'
import { IContainer } from '@/api/ContainerApi/types'
import { IZone } from '@/api/ZoneApi/types'
import { MutationTree } from 'vuex'
import { ContainerMutationsTypes, ContainerStateTypes } from '../interfaces'
import { MutationTypes } from './mutation-types'
import { remove } from 'lodash'

export const mutations: MutationTree<ContainerStateTypes> &
  ContainerMutationsTypes = {
  [MutationTypes.FETCH_START](state: ContainerStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.FETCH_CONTAINER_SUCCESS](
    state: ContainerStateTypes,
    payload: IContainer,
  ) {
    state.status = 'success'
    state.containers.push(payload)
  },
  [MutationTypes.FETCH_EMPTY_CONTAINER_SUCCESS](
    state: ContainerStateTypes,
    payload: IContainer,
  ) {
    state.status = 'success'
    state.emptyContainer = payload
  },
  [MutationTypes.FETCH_FULL_CONTAINER_SUCCESS](
    state: ContainerStateTypes,
    payload: IContainer,
  ) {
    state.status = 'success'
    state.fullContainer = payload
  },
  [MutationTypes.FETCH_ERROR](state: ContainerStateTypes) {
    state.status = 'error'
  },
  [MutationTypes.REMOVE_CONTAINERS](state: ContainerStateTypes) {
    state.status = ''
    state.containers = []
  },
  [MutationTypes.REMOVE_CONTAINER](
    state: ContainerStateTypes,
    payload: IContainer,
  ) {
    state.status = ''
    remove(state.containers, payload)
  },
  [MutationTypes.ZONE_LOCATION_START](state: ContainerStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.ZONE_LOCATION_SUCCESS](
    state: ContainerStateTypes,
    payload: IZone,
  ) {
    state.status = 'success'
    state.zone = payload
  },
  [MutationTypes.ZONE_LOCATION_ERROR](state: ContainerStateTypes) {
    state.status = 'error'
    state.zone = null
  },
  [MutationTypes.ORIGIN_ZONE_LOCATION_START](state: ContainerStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.ORIGIN_ZONE_LOCATION_SUCCESS](
    state: ContainerStateTypes,
    payload: IZone,
  ) {
    state.status = 'success'
    state.originZone = payload
  },
  [MutationTypes.ORIGIN_ZONE_LOCATION_ERROR](state: ContainerStateTypes) {
    state.status = 'error'
    state.originZone = null
  },
  [MutationTypes.REMOVE_ORIGIN_ZONE](state: ContainerStateTypes) {
    state.status = ''
    state.originZone = null
  },
  [MutationTypes.TARGET_ZONE_LOCATION_START](state: ContainerStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.TARGET_ZONE_LOCATION_SUCCESS](
    state: ContainerStateTypes,
    payload: IZone,
  ) {
    state.status = 'success'
    state.targetZone = payload
  },
  [MutationTypes.TARGET_ZONE_LOCATION_ERROR](state: ContainerStateTypes) {
    state.status = 'error'
    state.targetZone = null
  },
  [MutationTypes.REMOVE_TARGET_ZONE](state: ContainerStateTypes) {
    state.status = ''
    state.targetZone = null
  },
  [MutationTypes.REMOVE_ZONE](state: ContainerStateTypes) {
    state.status = ''
    state.zone = null
  },
  [MutationTypes.FETCH_FRACTIONS_START](state: ContainerStateTypes) {
    state.status = 'loading'
  },
  [MutationTypes.FETCH_FRACTIONS_SUCCESS](
    state: ContainerStateTypes,
    payload: IFraction[],
  ) {
    state.status = 'success'
    state.fractions = payload
  },
  [MutationTypes.FETCH_FRACTIONS_ERROR](state: ContainerStateTypes) {
    state.status = 'error'
    state.fractions = []
  },
  [MutationTypes.REMOVE_FRACTIONS](state: ContainerStateTypes) {
    state.status = ''
    state.fractions = []
  },
  [MutationTypes.CLEAR_STATE](state: ContainerStateTypes) {
    state.status = ''
    state.fractions = []
    state.containers = []
    state.originZone = null
    state.targetZone = null
    state.emptyContainer = null
    state.fullContainer = null
  },
}
