import AUTH_ROUTES from '@/api/AuthApi/config'
import EMPTYING_ORDER_ROUTES from '@/api/EmptyingOrderApi/config'
import ORDER_ROUTES from '@/api/OrderApi/config'
import SITE_ROUTES from '@/api/SiteApi/config'
import USER_ROUTES from '@/api/UserApi/config'

const apiRoutes = {
  AUTH: { ...AUTH_ROUTES },
  USER: { ...USER_ROUTES },
  EMPTYING_ORDER: { ...EMPTYING_ORDER_ROUTES },
  SITE: { ...SITE_ROUTES },
  ORDER: { ...ORDER_ROUTES },
}

export default apiRoutes
