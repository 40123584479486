import { GetterTree } from 'vuex'
import {
  ContainerGetterTypes,
  ContainerStateTypes,
  IRootState,
} from '../interfaces'

export const getters: GetterTree<ContainerStateTypes, IRootState> &
  ContainerGetterTypes = {
  scannedContainers: (state: ContainerStateTypes) => {
    return state.containers
  },
  scannedFullContainer: (state: ContainerStateTypes) => {
    return state.fullContainer || null
  },
  scannedEmptyContainer: (state: ContainerStateTypes) => {
    return state.emptyContainer || null
  },
  scannedZone: (state: ContainerStateTypes) => {
    return state.zone || null
  },
  scannedOriginZone: (state: ContainerStateTypes) => {
    return state.originZone || null
  },
  scannedTargetZone: (state: ContainerStateTypes) => {
    return state.targetZone || null
  },
  availableFractions: (state: ContainerStateTypes) => {
    return state.fractions || []
  },
  lastAPICallStatus: (state: ContainerStateTypes) => {
    return state.status
  },
}
