import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NewOrder from '@/views/NewOrder/NewOrder.vue'
import Root from '@/views/Root/Root.vue'
import User from '@/views/User/show.vue'
import authRoutes from '@/router/authRoutes'
import PageNotFound from '@/components/PageNotFound/index.vue'
import store from '@/store'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'RootView',
    component: Root,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orders/new',
    name: 'NewOrder',
    component: NewOrder,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      requiresAuth: true,
    },
  },
  ...authRoutes,
  { path: '/404', component: PageNotFound },
  { path: '/:catchAll(.*)', redirect: '/404' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(to => {
  if (to.meta.requiresAuth && !store.getters.currentUser) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
    }
  }
})

export default router
