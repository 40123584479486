import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32590fb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "order__container"
}
const _hoisted_2 = {
  key: 4,
  class: "order__info"
}
const _hoisted_3 = { class: "order__info-full" }
const _hoisted_4 = { class: "order__info-headline" }
const _hoisted_5 = { key: 5 }
const _hoisted_6 = { class: "order__actions" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderInfo = _resolveComponent("OrderInfo")!
  const _component_Select = _resolveComponent("Select")!
  const _component_CommentField = _resolveComponent("CommentField")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isGatheringOrder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.originZone)
            ? (_openBlock(), _createBlock(_component_OrderInfo, {
                key: 0,
                content: [
        { labelText: _ctx.t('newOrder.originZone'), bodyText: _ctx.originZone.area_name },
        { labelText: _ctx.t('meta.name'), bodyText: _ctx.originZoneBodyText },
      ],
                additionalCssClasses: 
        !_ctx.isOriginZoneActive ? 'order__info-deactivated' : ''
      
              }, null, 8, ["content", "additionalCssClasses"]))
            : _createCommentVNode("", true),
          (_ctx.targetZone)
            ? (_openBlock(), _createBlock(_component_OrderInfo, {
                key: 1,
                content: [
        { labelText: _ctx.t('newOrder.targetZone'), bodyText: _ctx.targetZone.area_name },
        { labelText: _ctx.t('meta.name'), bodyText: _ctx.targetZone.name },
      ]
              }, null, 8, ["content"]))
            : _createCommentVNode("", true),
          (_ctx.fullContainer)
            ? (_openBlock(), _createBlock(_component_OrderInfo, {
                key: 2,
                content: [
        {
          labelText: _ctx.t('newOrder.fullContainer'),
          bodyText: _ctx.fullContainer.name,
        },
        {
          labelText: _ctx.t('newOrder.containerType'),
          bodyText: _ctx.fullContainer.type_name,
        },
      ]
              }, null, 8, ["content"]))
            : _createCommentVNode("", true),
          (_ctx.emptyContainer)
            ? (_openBlock(), _createBlock(_component_OrderInfo, {
                key: 3,
                content: [
        {
          labelText: _ctx.t('newOrder.emptyContainer'),
          bodyText: _ctx.emptyContainer.name,
        },
        {
          labelText: _ctx.t('newOrder.containerType'),
          bodyText: _ctx.emptyContainer.type_name,
        },
      ]
              }, null, 8, ["content"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.containers, (container) => {
            return (_openBlock(), _createBlock(_component_OrderInfo, {
              "data-testid": "containerInfoWrapper",
              key: container.uuid,
              content: [
        {
          labelText: _ctx.t('newOrder.fullContainer'),
          bodyText: container.name,
        },
        {
          labelText: _ctx.t('newOrder.containerType'),
          bodyText: container.type_name,
        },
      ]
            }, null, 8, ["content"]))
          }), 128)),
          (_ctx.showFractionSelect)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.t('meta.fraction')) + ":", 1),
                  _createVNode(_component_Select, {
                    modelValue: _ctx.selectedFractionUuid,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFractionUuid) = $event)),
                      _ctx.handleFractionSelect
                    ],
                    options: _ctx.fractionOptions,
                    disabled: _ctx.orderCreated
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.showCommentfield)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_CommentField, {
                  modelValue: _ctx.commentText,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.commentText) = $event)),
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.commentText = $event as string))
                  ],
                  disabled: _ctx.orderCreated
                }, null, 8, ["modelValue", "disabled"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        disabled: !_ctx.orderFilled || _ctx.orderCreated,
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createOrder && _ctx.createOrder(...args))),
        class: "actions__create-order actions__cta"
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 0 }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.createOrderText), 1)
      ], 8, _hoisted_7),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleScanAgain && _ctx.handleScanAgain(...args))),
        class: "actions__scan-again actions__cta"
      }, _toDisplayString(_ctx.scanButtonText), 1)
    ])
  ], 64))
}