import AuthApi from '@/api/AuthApi'
import { ILoginParams } from '@/api/AuthApi/types'
import UserApi from '@/api/UserApi'
import { ActionTree } from 'vuex'
import { AuthStateTypes, IRootState } from '../interfaces'
import { ActionTypes } from './action-types'
import { MutationTypes } from './mutation-types'
import {
  loginWithAdRedirect,
  logoutWithAdRedirect,
} from '@/utils/active_directory_helper'

export const actions: ActionTree<AuthStateTypes, IRootState> & AuthStateTypes =
  {
    async [ActionTypes.AD_LOGIN]({ commit }) {
      commit(MutationTypes.LOGIN_START)
      await loginWithAdRedirect()
      const user = await UserApi.getUser()
      if (user) {
        commit('setLanguage', user.language)
        commit(MutationTypes.LOGIN_SUCCESS, { user })
      }
    },
    async [ActionTypes.SESSION_LOGIN]({ commit }, payload: ILoginParams) {
      commit(MutationTypes.LOGIN_START)
      const user = await AuthApi.login(payload)
      if (user) {
        commit('setLanguage', user.language)
        commit(MutationTypes.LOGIN_SUCCESS, { user })
      }
    },
    async [ActionTypes.LOGOUT]({ commit }) {
      await logoutWithAdRedirect()
      commit(MutationTypes.LOGOUT)
      await AuthApi.logout()
    },
    async [ActionTypes.FETCH_USER]({ commit }) {
      commit(MutationTypes.FETCH_USER_START)
      const user = await UserApi.getUser()
      if (user) {
        commit('setLanguage', user.language)
        commit(MutationTypes.FETCH_USER_SUCCESS, user)
      }
    },
  }
